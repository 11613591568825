import React from 'react';
import classnames from 'classnames';
import Button, {ButtonProps} from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import styles from './index.module.scss';

type Props = ButtonProps & {
  label: string;
  onClick?: () => void;
  className?: string | string[];
  selected?: boolean;
  href?: string;
  loading?: boolean;
};

const StandardButton: React.FC<Props> = ({
  label,
  href,
  onClick,
  selected,
  className,
  loading = false,
  ...otherProps
}) => {
  return (
    <Button
      variant="outlined"
      href={href}
      onClick={onClick}
      className={classnames(className)}
      {...otherProps}
      classes={{
        outlined: selected ? styles.outlinedSelected : undefined,
        contained: styles.contained,
      }}
    >
      {loading && <CircularProgress className={styles.loading} size={24} />}
      {label}
    </Button>
  );
};

export default StandardButton;
