import React from 'react';
import {useNavigate} from 'react-router-dom';
import Typography from '@material-ui/core/Typography';

import StandardButton from '@/components/button';

import styles from './index.module.scss';

type Props = {
  title?: string;
};

const NotFound: React.FC<Props> = ({title}) => {
  const navigate = useNavigate();

  const handleGoBack = (): void => navigate(-1);

  return (
    <div className={styles.container}>
      <Typography variant="h1" className={styles.title}>
        Page Not Found {title}
      </Typography>
      <Typography align="center" variant="body1" className={styles.desc}>
        This page does not exist, click the button to go back
      </Typography>
      <StandardButton label="Go back" onClick={handleGoBack} />
    </div>
  );
};

export default NotFound;
