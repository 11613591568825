import {useEffect, useState} from 'react';
import {getDownloadURL, getStorage, ref, uploadBytesResumable} from 'firebase/storage';
import {v4 as uuidv4} from 'uuid';
import {DocumentReference, getDoc} from 'firebase/firestore';
import formatDate from 'date-fns/format';
import _isDate from 'lodash/isDate';
import _isEqual from 'lodash/isEqual';
import _isEmpty from 'lodash/isEmpty';

import {Address, Booking} from '@/types/booking';
import {SalesAgency, User, CustomObject, SalesAgentUser, AdminUser} from '@/types/form';
import {Project} from '@/types/project';
import {Level} from '@/types/premium';
import {UnitType} from '@/types/sales-chart';
import {LabelValueOption} from '@/types';
import {PremiumPricingType, DiscountPricingType} from '@/constants/project';
import {useAppContext} from '@/context';
import * as appActions from '@/context/actions';
import {BookingStatus, UserTypes} from '@/constants';
import {MALAYSIAN_STATES_OPTIONS} from '@/constants/options';

import {ApiError} from './api';

/**
 * Generate parent route path, requires '{path}/*'
 */
export const getParentRoutePath = (path: string): string => `${path}/*`;

/**
 *  Use App Loading
 */
export const useAppLoading = (loading: boolean): void => {
  const [, dispatch] = useAppContext();
  useEffect(() => {
    dispatch(appActions.setAppLoading(loading));
    return () => {
      dispatch(appActions.setAppLoading(false));
    };
  }, [dispatch, loading]);
};

/**
 * Passed in project must be populated
 */
export const useProjectPermission = (
  project?: Project,
): {readAccess: boolean | null; writeAccess: boolean | null; loading: boolean} => {
  const [readAccess, setReadAccess] = useState<boolean | null>(null);
  const [writeAccess, setWriteAccess] = useState<boolean | null>(null);
  const [loading, setIsLoading] = useState(true);

  const [{user}] = useAppContext();

  const agentsInAgencies: string[] = project?.salesAgencies?.length
    ? project.salesAgencies
        .map((agency) => (agency as SalesAgency).agents.map((agent) => agent.id as string) || [])
        .reduce((prevAgents, currentAgents) => [...prevAgents, ...currentAgents], [])
    : [];

  useEffect(() => {
    if (user && project) {
      switch (user.role) {
        case UserTypes.SUPERADMIN:
          setReadAccess(true);
          setWriteAccess(true);
          break;
        case UserTypes.ADMIN: {
          const access = !!project.admins?.some((a) => !!(a === user.id || (a as User)?.id === user.id));
          setReadAccess(access);
          setWriteAccess(access);
          break;
        }
        case UserTypes.SALESAGENT:
          setReadAccess(
            !!agentsInAgencies.some((a) => a === user.id) ||
              !!project.salesAgents?.some((a) => !!(a === user.id || (a as User)?.id === user.id)),
          );
          break;
        case UserTypes.LAWYER:
          setReadAccess(!!project.lawyers?.some((a) => !!(a === user.id || (a as User)?.id === user.id)));
          break;
        default:
          break;
      }
      setIsLoading(false);
    }
  }, [user, project]);

  return {
    readAccess,
    writeAccess,
    loading,
  };
};

/**
 * Use File Input Change
 */
export const getFileDownloadURL = (file: File, refLink?: string): Promise<string> => {
  return new Promise((resolve) => {
    const storage = getStorage();
    const fileRef = ref(storage, `${refLink || 'images'}/${uuidv4()}`);
    const uploadTask = uploadBytesResumable(fileRef, file);
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(`Upload is ${progress}% done`);
        switch (snapshot.state) {
          case 'paused':
            console.log('Upload is paused');
            break;
          case 'running':
            console.log('Upload is running');
            break;
          default:
            break;
        }
      },
      (error) => {
        throw Error(error.code);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          resolve(downloadURL);
        });
      },
    );
  });
};

export const getFilePreviewURL = (file?: File): string => {
  const objURL = file ? URL.createObjectURL(file) : '';
  return objURL;
};

export const userCanCancelBooking = (user: User | AdminUser | SalesAgentUser, booking: Booking): boolean => {
  return !!(
    user?.role === UserTypes.SUPERADMIN ||
    user?.role === UserTypes.ADMIN ||
    (booking.status === BookingStatus.RESERVED &&
      user?.role === UserTypes.SALESAGENT &&
      ((user as unknown) as SalesAgentUser).isSalesLead) ||
    (booking.status === BookingStatus.BOOKED &&
      user?.role === UserTypes.SALESAGENT &&
      ((user as unknown) as SalesAgentUser).isSalesLead)
  );
};

export const getDateTimeDisplayString: (date?: string | Date) => string = (date) => {
  return date ? formatDate(new Date(date), 'd MMM yyyy, HH:mm') : 'N/A';
};
export const getDateDisplayString: (date?: string | Date) => string = (date) => {
  return date ? formatDate(new Date(date), 'd MMM yyyy') : 'N/A';
};

export const getDateDisplayStringForReports: () => string = () => formatDate(new Date(), 'yyyyMMdd');

const isEmpty = (v: boolean | string | number | number[] | string[] | undefined | null) =>
  typeof v === 'boolean' || typeof v === 'number' ? false : _isEmpty(v);

export const getObjectDiff = (oLeft: CustomObject, oRight: CustomObject, keys: string[]): CustomObject => {
  const diff: CustomObject = {};

  keys.forEach((key) => {
    const left = oLeft[key];
    const right = oRight[key];

    if (_isDate(left) || _isDate(right)) {
      // lodash's isEmpty doesn't work with date
      if (!_isEqual(left, right)) {
        diff[key] = _isDate(right) ? right : null;
      }
    } else if (!(isEmpty(left) && isEmpty(right))) {
      if (!_isEqual(left, right)) {
        diff[key] = isEmpty(right) ? null : right;
      }
    }
  });
  return diff;
};

// https://stackoverflow.com/a/47767860
export const getFileExtensionFromURL = (url: string): string =>
  (url.split(/[#?]/)?.[0]?.split('.')?.pop() || '').trim();

const numberWithCommas = (x: string | number) => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const getMoneyDisplayString = (amount = 0, currency?: string, accurateMode?: boolean): string =>
  `${currency || 'RM'.toUpperCase()} ${accurateMode ? numberWithCommas(amount.toFixed(2)) : numberWithCommas(amount)}`;

export const getDateFromFirestoreDate = (at: unknown): Date | undefined => {
  // @ts-ignore
  return at?.seconds ? new Date(at.seconds * 1000) : undefined;
};

export const getUserFromDocumentReference = async (
  u?: DocumentReference,
  populate?: boolean,
): Promise<User | string | undefined> => {
  if (!u) {
    return undefined;
  }

  if (!populate) {
    return u.id;
  }

  return (await getDoc(u)).data() as User;
};

export const getErrorMessage = (
  e: unknown,
  generalErrorMessage = 'Something went wrong, please try again or contact support for assistance.',
): string => {
  if (e instanceof ApiError) {
    if (e.message) {
      return e.message;
    }
  }

  // @ts-ignore
  return e?.message || generalErrorMessage;
};

export const getAddressDisplayString = (address: Address): string => {
  let stateLabel = address?.state || '';
  const stateOptionsLabel = getValueFromOptions(MALAYSIAN_STATES_OPTIONS, address.state);

  if (stateOptionsLabel && stateOptionsLabel !== 'other') {
    stateLabel = stateOptionsLabel;
  }

  const addressArr = [
    ...(address.street ? [address.street] : []),
    ...(address.postCode ? [address.postCode] : []),
    ...(stateLabel ? [stateLabel] : []),
    ...(address.country ? [address.country] : []),
  ];
  return addressArr.join(', ');
};

export const getUnitTypesDisplayString = (unitTypeIDs?: string[], unitTypes?: UnitType[]): string =>
  unitTypeIDs?.map((id) => unitTypes?.find((ut) => ut.id === id)?.name || id)?.join(', ') || '';

export const getLevelPremiumName = (levelPremium: Level, unitTypes?: UnitType[]): string => {
  const {fromLevel, toLevel, pricingType, amount, increase, unitTypeIDs} = levelPremium;
  return `From ${fromLevel ? `Level ${fromLevel}` : 'Lowest'} to ${toLevel ? `Level ${toLevel}` : 'Highest'}, +${
    pricingType === PremiumPricingType.FIXED ? 'RM' : ''
  }${amount}${pricingType === PremiumPricingType.BASE_PRICE_PERCENTAGE ? '%' : ''}
    ${increase ? '(increase every level)' : ''}
    ${
      unitTypeIDs?.length && unitTypes?.length ? ` for Type ${getUnitTypesDisplayString(unitTypeIDs, unitTypes)}` : ''
    }`;
};

export const getPremiumDisplayPrice = (pricingType: PremiumPricingType, amount: number): string =>
  `+ ${pricingType === PremiumPricingType.FIXED ? getMoneyDisplayString(amount) : `${amount}%`}`;

export const getDiscountDisplayPrice = (pricingType: DiscountPricingType, amount: number): string =>
  `- ${pricingType === DiscountPricingType.FIXED ? getMoneyDisplayString(amount) : `${amount}%`}`;

export const sanitiseNric = (nric = ''): string => {
  const sanitizedStr = nric.replace(/(-|_)/g, '').trim();
  return sanitizedStr;
};

export const getNricDisplayString = (nric: string): string => {
  return nric.replace(/(\d{6})(\d{2})(\d{4})/, '$1-$2-$3');
};

export const getValueFromOptions = (options: LabelValueOption[], value: string): string | undefined => {
  return options.find((o) => o.value === value)?.label;
};

export const convertToFirebaseSafeData = (obj: {[x in string]: any}): {[x in string]: any} => {
  const t = obj;
  // eslint-disable-next-line no-restricted-syntax
  for (const v in t) {
    if (typeof t[v] === 'object') convertToFirebaseSafeData(t[v]);
    else if (t[v] === undefined) delete t[v];
  }
  return t;
};

export const formatNumberToSort = (num: number, compareLength: number): string => {
  // compareLength === number of digits in value to compare with
  if (Number(num) < 10 ** compareLength - 1) {
    return num.toString().padStart(compareLength, '0');
  }
  return num.toString();
};

export const compareUnitNames = (
  firstUnitName: string,
  secondUnitName: string,
  direction: 'asc' | 'desc' = 'asc',
): number => {
  const aString = firstUnitName.split('-');
  const bString = secondUnitName.split('-');
  let aToCompare = firstUnitName;
  let bToCompare = secondUnitName;

  // Can only sort default unitNames generated by system
  if (aString.length === 3) {
    aToCompare = [
      aString[0],
      formatNumberToSort(Number(aString[1]), bString[1].length),
      formatNumberToSort(Number(aString[2]), bString[2].length),
    ].join('-');
  }
  if (bString.length === 3) {
    bToCompare = [
      bString[0],
      formatNumberToSort(Number(bString[1]), aString[1].length),
      formatNumberToSort(Number(bString[2]), aString[2].length),
    ].join('-');
  }

  return direction === 'asc' ? aToCompare.localeCompare(bToCompare) : bToCompare?.localeCompare(aToCompare);
};
