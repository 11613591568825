import {useCallback} from 'react';

import {useAppContext} from '@/context';
import * as appActions from '@/context/actions';

import {auth} from './firebase';

type AuthTokens = {
  authToken: string | null;
};

const AUTH_TOKEN_KEY = 'auth_token';

/**
 * Set tokens to browser storage
 */
export const setAuthToken = (authToken: string): void => {
  sessionStorage.setItem(AUTH_TOKEN_KEY, authToken);
};

/// Temporary

/**
 * Retrieve authentication token & refresh token from session storage
 */
export const getAuthToken = (): AuthTokens => ({
  authToken: sessionStorage.getItem(AUTH_TOKEN_KEY) === 'undefined' ? null : sessionStorage.getItem(AUTH_TOKEN_KEY),
});

/**
 * Clear authentication token & refresh token & email from session storage
 */
export const clearAuthToken = (): void => {
  sessionStorage.removeItem(AUTH_TOKEN_KEY);
};

/**
 * Log out user
 */
export const useLogout = (): {logout: () => void} => {
  const [, dispatch] = useAppContext();

  const logout = useCallback(() => {
    auth.signOut();
    clearAuthToken();
    dispatch(appActions.clearState());
  }, [dispatch]);

  return {logout};
};
