import {User} from '@/types/form';

import {AppAction} from './actions';
import {ActionTypes} from './actionTypes';

export type AppState = {
  app: {
    loading: boolean;
  };
  user?: User;
  selectedProjectForReport: {
    id: string;
    name: string;
    salesChartId: string;
  };
};

export const initialState: AppState = {
  app: {
    loading: false,
  },
  selectedProjectForReport: {
    id: '',
    name: '',
    salesChartId: '',
  },
};

export const reducer = (state: AppState, action: AppAction): AppState => {
  switch (action.type) {
    case ActionTypes.SET_APP_LOADING: {
      const {isLoading: loading} = action.payload;
      return {
        ...state,
        app: {
          ...state.app,
          loading,
        },
      };
    }

    case ActionTypes.SET_USER: {
      const {user} = action.payload;
      return {
        ...state,
        user,
      };
    }

    case ActionTypes.SET_CURRENT_PROJECT_FOR_REPORT: {
      const {id, name, salesChartId} = action.payload;
      return {
        ...state,
        selectedProjectForReport: {
          id,
          name,
          salesChartId,
        },
      };
    }

    case ActionTypes.CLEAR_STATE: {
      return {...initialState};
    }

    default:
      return state;
  }
};
