export enum PremiumType {
  ADDON = 'ADDON',
  LEVEL = 'BY_LEVEL',
}

export enum PremiumPricingType {
  FIXED = 'FIXED',
  BASE_PRICE_PERCENTAGE = 'BASE_PRICE_PERCENTAGE',
}

export const premiumPricingTypeOptions = [
  {
    value: PremiumPricingType.FIXED,
    label: 'Fixed',
  },
  {
    value: PremiumPricingType.BASE_PRICE_PERCENTAGE,
    label: '% of Base Unit Price',
  },
];

export enum DiscountPricingType {
  FIXED = 'FIXED',
  BASE_PRICE_PERCENTAGE = 'BASE_PRICE_PERCENTAGE',
  TOTAL_PRICE_PERCENTAGE = 'TOTAL_PRICE_PERCENTAGE',
}

export const discountPricingTypeOptions = [
  {
    value: DiscountPricingType.FIXED,
    label: 'Fixed',
  },
  {
    value: DiscountPricingType.BASE_PRICE_PERCENTAGE,
    label: '% of Base Unit Price',
  },
  {
    value: DiscountPricingType.TOTAL_PRICE_PERCENTAGE,
    label: '% of Total Unit Price',
  },
];
