import React, {createContext, useContext, useReducer, Dispatch} from 'react';

import {AppAction} from './actions';
import {reducer, initialState, AppState} from './reducer';

export const AppContext = createContext<[AppState, Dispatch<AppAction>]>([initialState, () => null]);
export const useAppContext = (): [AppState, Dispatch<AppAction>] => useContext(AppContext);

export const AppContextProvider: React.FC = (props) => {
  const appReducer = useReducer(reducer, initialState);
  return <AppContext.Provider value={appReducer} {...props} />;
};
