import React, {Suspense} from 'react';
import {LinearProgress} from '@material-ui/core';
import {createTheme, StylesProvider, ThemeProvider} from '@material-ui/core/styles';
import {ToastContainer, Flip} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {useAppContext, AppContextProvider} from '@/context';
import LoadingBlocker from '@/components/loading-blocker';

import RootRouter from './routes';

import './styles/globals.scss';

// Override all Typography font-family
const theme = createTheme({
  typography: {
    fontFamily: ['Poppins', 'sans-serif'].join(','),
  },
  palette: {
    primary: {
      light: '#e1b76c',
      main: '#ffbd54',
    },
  },
});

const App: React.FC = () => {
  const [{app}] = useAppContext();
  const {loading} = app;

  return (
    <>
      <RootRouter />

      <LoadingBlocker isLoading={loading} />

      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Flip}
        bodyClassName="MAL__toast"
      />
    </>
  );
};

const ProvidersWrapper: React.FC = () => (
  <AppContextProvider>
    <StylesProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Suspense fallback={<LinearProgress />}>
          <App />
        </Suspense>
      </ThemeProvider>
    </StylesProvider>
  </AppContextProvider>
);

export default ProvidersWrapper;
