import {PremiumType} from '@/constants/project';
import {BookingStatusViewerOption, LabelValueOption} from '@/types';

import {
  BookingStatus,
  BookingStatusViewer,
  ChartBookingStatusRgbColor,
  LoanBuyerType,
  MalaysianStates,
  ProcessingFeePaymentType,
} from '.';

/**
 * Building type options
 */
export const BUILDING_TYPE_OPTIONS: LabelValueOption[] = [
  {label: 'Strata', value: 'strata'},
  {label: 'Landed', value: 'landed'},
  {label: 'Mixed', value: 'mixed', disabled: true},
];

/**
 * Premium type options
 */
export const PREMIUM_TYPE_OPTIONS: {label: string; value: PremiumType}[] = [
  {label: 'By Level', value: PremiumType.LEVEL},
  {label: 'Addon', value: PremiumType.ADDON},
];

export const BOOKING_STATUS_OPTIONS: LabelValueOption[] = [
  {label: 'Reserved', value: BookingStatus.RESERVED},
  {label: 'Booked', value: BookingStatus.BOOKED},
  {label: 'Sold', value: BookingStatus.SOLD},
];

export const BOOKING_STATUS_VIEWER_OPTIONS: BookingStatusViewerOption[] = [
  {label: 'Reserved', value: BookingStatusViewer.RESERVED, color: ChartBookingStatusRgbColor.RESERVED},
  {label: 'Booked', value: BookingStatusViewer.BOOKED, color: ChartBookingStatusRgbColor.BOOKED},
  {label: 'Sold', value: BookingStatusViewer.SOLD, color: ChartBookingStatusRgbColor.SOLD},
  {label: 'Cancelling', value: BookingStatusViewer.CANCELLING, color: ChartBookingStatusRgbColor.CANCELLED},
  {label: 'Blocked', value: BookingStatusViewer.BLOCKED, color: ChartBookingStatusRgbColor.BLOCKED},
];

export const STATUS_OPTIONS = {
  active: 'Active',
  inactive: 'Inactive',
};

export const ACTIVE_STATUS_OPTIONS = [
  {value: 'active', label: STATUS_OPTIONS.active},
  {value: 'inactive', label: STATUS_OPTIONS.inactive},
];

export const ACTIVE_POSITION_OPTIONS = [
  {value: 'salesLead', label: 'Sales Lead'},
  {value: 'salesAgent', label: 'Sales Agent'},
];

export const LOAN_BUYER_TYPE_OPTIONS = [
  {value: LoanBuyerType.CASH, label: 'Cash'},
  {value: LoanBuyerType.LOAN, label: 'Loan'},
];

export const PROCESSING_FEE_PAYMENT_TYPE_OPTIONS = [
  {value: ProcessingFeePaymentType.CREDIT_CARD, label: 'Credit card'},
  {value: ProcessingFeePaymentType.CHEQUE, label: 'Cheque'},
  {value: ProcessingFeePaymentType.CASH, label: 'Cash'},
  {value: ProcessingFeePaymentType.ONLINE_TRANSFER, label: 'Online transfer'},
];

export const OCCUPATION_CATEGORY_OPTIONS = [
  {value: 'managers', label: 'Legislators, Senior Officials and Managers'},
  {value: 'professionals', label: 'Professionals'},
  {value: 'technicalAndAssocitaeProfessionals', label: 'Technicians and Associate Professionals'},
  {value: 'clericalWorkers', label: 'Clerical Workers'},
  {value: 'serviceWorkers', label: 'Service Workers and Shop and Market Sales Workers'},
  {value: 'agriculturalAndFishery', label: 'Skilled Agricultural and Fishery Workers'},
  {value: 'craftRelatedTrades', label: 'Craft and Related Trades Workers'},
  {value: 'plantAndMachineOperators', label: 'Plant and Machine-operators and Assemblers'},
  {value: 'elementaryOccupations', label: 'Elementary Occupations'},
  {value: 'armedForces', label: 'Armed Forces'},
  {value: 'other', label: 'Other'},
];

export const MARITAL_STATUS_OPTIONS = [
  {value: 'married', label: 'Married'},
  {value: 'widowed', label: 'Widowed'},
  {value: 'seperated', label: 'Seperated'},
  {value: 'divorced', label: 'Divorced'},
  {value: 'single', label: 'Single'},
  {value: 'other', label: 'Other'},
];

export const MALAYSIAN_STATES_OPTIONS = [
  {value: MalaysianStates.JOHOR, label: 'Johor'},
  {value: MalaysianStates.KEDAH, label: 'Kedah'},
  {value: MalaysianStates.KELANTAN, label: 'Kelantan'},
  {value: MalaysianStates.KUALA_LUMPUR, label: 'Kuala Lumpur'},
  {value: MalaysianStates.LABUAN, label: 'Labuan'},
  {value: MalaysianStates.MELAKA, label: 'Melaka'},
  {value: MalaysianStates.NEGERI_SEMBILAN, label: 'Negeri Sembilan'},
  {value: MalaysianStates.PAHANG, label: 'Pahang'},
  {value: MalaysianStates.PENANG, label: 'Penang'},
  {value: MalaysianStates.PERAK, label: 'Perak'},
  {value: MalaysianStates.PERLIS, label: 'Perlis'},
  {value: MalaysianStates.PUTRAJAYA, label: 'Putrajaya'},
  {value: MalaysianStates.SABAH, label: 'Sabah'},
  {value: MalaysianStates.SARAWAK, label: 'Sarawak'},
  {value: MalaysianStates.SELANGOR, label: 'Selangor'},
  {value: MalaysianStates.TERENGGANU, label: 'Terengganu'},
];

export const BUYER_IS_BUMI_OPTIONS = [
  {value: 'yes', label: 'Bumi'},
  {value: 'no', label: 'Non-Bumi'},
];
export const BUYER_IS_KELANTANESE_OPTIONS = [
  {value: 'MELAYU_ANAK_KELANTAN', label: 'Melayu Anak Kelantan'},
  {value: 'BUKAN_MELAYU_ANAK_KELANTAN', label: 'Bukan Melayu Anak Kelantan'},
  {value: 'MELAYU_BUKAN_ANAK_KELANTAN', label: 'Melayu Bukan Anak Kelantan'},
];

export const SALUTATION_OPTIONS = [
  {value: 'MR', label: 'Mr.'},
  {value: 'MS', label: 'Ms.'},
  {value: 'DR', label: 'Dr.'},
  {value: 'DATO', label: 'Dato.'},
  {value: 'DATIN', label: 'Datin.'},
  {value: 'TAN_SRI', label: 'Tan Sri.'},
];

export const BUYER_RACE_OPTIONS = [
  {value: 'CHINESE', label: 'Cina'},
  {value: 'MALAY', label: 'Melayu'},
  {value: 'Indian', label: 'India'},
  {value: 'OTHER', label: 'Lain-Lain'},
];
