import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import styles from './index.module.scss';

type Props = {
  isLoading: boolean;
};

const LoadingBlocker: React.FC<Props> = ({isLoading}) => {
  return (
    <Backdrop classes={{root: styles.backdrop_root}} open={isLoading}>
      <CircularProgress size={50} />
    </Backdrop>
  );
};

export default LoadingBlocker;
