import React, {lazy} from 'react';
import {BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom';

import NotFound from '@/pages/error-not-found';
import {getParentRoutePath} from '@/utils';
import urls from '@/constants/routes';

const AuthRouter = lazy(() => import('./auth-router'));
const PrivateRouter = lazy(() => import('./private-router'));

const RootRouter: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path={urls.ROOT} element={<Navigate to={urls.PRIVATE.ROOT} replace />} />
        <Route path={getParentRoutePath(urls.AUTH.ROOT)} element={<AuthRouter />} />
        <Route path={getParentRoutePath(urls.PRIVATE.ROOT)} element={<PrivateRouter />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default RootRouter;
