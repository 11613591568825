export enum UserTypes {
  SUPERADMIN = 'SUPERADMIN',
  ADMIN = 'ADMIN',
  LAWYER = 'LAWYER',
  SALESAGENT = 'SALESAGENT',
}

export enum BookingStatus {
  RESERVED = 'RESERVED',
  BOOKED = 'BOOKED',
  SOLD = 'SOLD',
  EXPIRED = 'EXPIRED',
}

export enum ClickableBookingStatus {
  RESERVED = 'RESERVED',
  BOOKED = 'BOOKED',
  SOLD = 'SOLD',
  EXPIRED = 'EXPIRED',
  CANCELLED = 'CANCELLED',
}

export enum ChartBookingStatus {
  RESERVED = 'Reserved',
  BOOKED = 'Booked',
  SOLD = 'Sold',
  CANCELLED = 'Cancelled',
}

export enum ChartBookingStatusRgbColor {
  BOOKED = 'rgb(228, 232, 118)',
  RESERVED = 'rgb(255, 99, 132)',
  SOLD = 'rgb(75, 192, 192)',
  CANCELLED = 'rgb(53, 162, 235)',
  AVAILABLE = 'rgb(227, 175, 123)',
  BLOCKED = 'rgb(153, 153, 153)',
}

export enum BookingStatusViewer {
  RESERVED = 'RESERVED',
  BOOKED = 'BOOKED',
  SOLD = 'SOLD',
  CANCELLING = 'CANCELLING',
  CANCELLED = 'CANCELLED',
  EXPIRED = 'EXPIRED',
  EXPIRING = 'EXPIRING',
  BLOCKED = 'BLOCKED',
}

export enum ProcessingFeePaymentType {
  CREDIT_CARD = 'CREDIT_CARD',
  CHEQUE = 'CHEQUE',
  CASH = 'CASH',
  ONLINE_TRANSFER = 'ONLINE_TRANSFER',
}

export enum RefundStatus {
  NOT_APPLICABLE = 'NOT_APPLICABLE',
  REFUNDING = 'REFUNDING',
  REFUNDED = 'REFUNDED',
}

export enum LoanBuyerType {
  CASH = 'CASH',
  LOAN = 'LOAN',
}

/**
 * Unit name alphabet temporary
 */
export const getUnitNamePrefix = (idx: number): string => {
  const list = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
  ];

  if (idx >= list.length) {
    return `${list[idx - list.length * (list.length % idx)]}${list.length % idx}`;
  }
  return list[idx];
};

export const EMPTY_VALUE_DISPLAY_STRING = 'N/A';
export const EMPTY_VALUE_STRING = '_EMPTY';
export const DAY_MILLISECONDS = 24 * 60 * 60 * 1000;

export const EMPTY_SELECT_VALUE = '__EMPTY__';
export const ADD_NEW_VALUE = '__ADD_NEW_VALUE_';

export const DEFAULT_RESERVATION_PERIOD = 3;
export const DEFAULT_RESERVATION_WARNING_PERIOD = 1;

export enum MalaysianStates {
  JOHOR = 'JOHOR',
  KEDAH = 'KEDAH',
  KELANTAN = 'KELANTAN',
  KUALA_LUMPUR = 'KUALA_LUMPUR',
  LABUAN = 'LABUAN',
  MELAKA = 'MELAKA',
  NEGERI_SEMBILAN = 'NEGERI_SEMBILAN',
  PAHANG = 'PAHANG',
  PENANG = 'PENANG',
  PERAK = 'PERAK',
  PERLIS = 'PERLIS',
  PUTRAJAYA = 'PUTRAJAYA',
  SABAH = 'SABAH',
  SARAWAK = 'SARAWAK',
  SELANGOR = 'SELANGOR',
  TERENGGANU = 'TERENGGANU',
}

export const getMonthName = (idx: number): string => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  if (idx >= months.length) {
    return `${months[idx - months.length * (months.length % idx)]}${months.length % idx}`;
  }
  return months[idx];
};
