export const ERROR_CODES = <const>{
  FIREBASE_ERROR: 'E000',
  FIREAUTH_ERROR: 'E001',
  INSUFFICIENT_PERMISSION: 'E002',
  INVALID_ROLE: 'E003',
  INVALID_EMAIL: 'E004',
  DUPLICATED: 'E005',
  NOT_FOUND: 'E006',
  ERR_VERIFY_RECAPTCHA: 'E007',
  PDF_TEMPLATE_NOT_FOUND: 'E008',
  EXTENSION_ERROR: 'E009',

  UNKNOWN_API_ERROR: 'E500',
};
