import axios from 'axios';

import {ERROR_CODES} from '@/constants/error-codes';

import {getAuthToken} from './auth';

type SuccessResponse = {
  success: true;
  data: any;
};

type ErrorResponse = {
  success: false;
  errorCode: string;
  errorMessage?: string;
};

// @ts-ignore
export class ApiError extends Error {
  constructor(public code: string, public message?: string) {
    super();
    this.code = code;
    this.message = message;
  }
}

const api = axios.create({
  baseURL: process.env.REACT_APP_FASTIFY_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use((config) => {
  const {authToken} = getAuthToken();

  if (!config?.headers) {
    throw new Error(`Expected 'config' and 'config.headers' not to be undefined`);
  }

  if (authToken) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${authToken}`;
  }
  return config;
});

export const apiQuery = async (
  url: string,
  method: 'get' | 'post' | 'delete' | 'put',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any = {},
): Promise<SuccessResponse> => {
  try {
    const {data: result}: {data: SuccessResponse | ErrorResponse} = await api[method](url, data);
    if (result.success) {
      return result;
    }
    throw getErrorToThrow(data);
  } catch (e) {
    // @ts-ignore
    throw getErrorToThrow(e?.response.data);
  }
};

const getErrorToThrow = (data?: ErrorResponse) => {
  const error = {
    code: data?.errorCode || ERROR_CODES.UNKNOWN_API_ERROR,
    message: data?.errorMessage || undefined,
  };
  return new ApiError(error.code, error.message);
};

export default api;
