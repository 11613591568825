/**
 *   PATH_NAME: For nested routes purpose (Only uses path name instead of full path)
 *   FULL_PATH: For navigating to route outside of the current parent route
 */

const authRoutes = () => {
  const ROOT = '/auth';

  const getRouteObj = (path: string) => ({
    PATH_NAME: path,
    FULL_PATH: `${ROOT}/${path}`,
  });

  return {
    ROOT,
    SIGN_IN: getRouteObj('sign-in'),
    FORGOT_PASSWORD: getRouteObj('forgot-password'),
  };
};

const privateRoutes = () => {
  const ROOT = '/~';

  const getStandardRouteObject = (root: string, pathName: string, usePathName = false) => ({
    PATH_NAME: pathName,
    FULL_PATH: `${root}/${pathName}`,
    ADD: {
      PATH_NAME: usePathName ? `${pathName}/add` : `add`,
      FULL_PATH: `${root}/${pathName}/add`,
    },
    EDIT: (id: string) => ({
      PATH_NAME: usePathName ? `${pathName}/${id}/edit` : `${id}/edit`,
      FULL_PATH: `${root}/${pathName}/${id}/edit`,
    }),
  });

  const getViewOnlyRouteObject = (root: string, pathName: string) => ({
    PATH_NAME: pathName,
    FULL_PATH: `${root}/${pathName}`,
  });

  return {
    ROOT,
    PROJECTS: {
      ...getStandardRouteObject(ROOT, 'projects'),
      VIEW: (id: string) => ({
        PATH_NAME: `${id}`,
        FULL_PATH: `${ROOT}/projects/${id}`,
      }),

      BOOKINGS: (id: string) => ({
        PATH_NAME: `${id}/bookings`,
        FULL_PATH: `${ROOT}/projects/${id}/bookings`,
      }),

      DISCOUNTS: (id: string) => ({
        ...getStandardRouteObject(`${ROOT}/projects/${id}`, 'discounts', true),
        PATH_NAME: `${id}/discounts`,
        FULL_PATH: `${ROOT}/projects/${id}/discounts`,
      }),
      ADDONS: (id: string) => ({
        ...getStandardRouteObject(`${ROOT}/projects/${id}`, 'addons', true),
        PATH_NAME: `${id}/addons`,
        FULL_PATH: `${ROOT}/projects/${id}/addons`,
      }),

      /* Sales Chart */
      SALES_CHART: (id: string) => ({
        PATH_NAME: `${id}/sales-chart`,
        FULL_PATH: `${ROOT}/projects/${id}/sales-chart`,
      }),
      NEW_SALES_CHART: (id: string) => ({
        PATH_NAME: `${id}/sales-chart/new`,
        FULL_PATH: `${ROOT}/projects/${id}/sales-chart/new`,
      }),
      EDIT_SALES_CHART: (id: string) => ({
        PATH_NAME: `${id}/sales-chart/edit`,
        FULL_PATH: `${ROOT}/projects/${id}/sales-chart/edit`,
      }),

      /* Unit */
      UNIT: (id: string, unitName: string) => ({
        PATH_NAME: `${id}/units/${unitName}`,
        FULL_PATH: `${ROOT}/projects/${id}/unit/${unitName}`,
      }),
    },
    BOOKINGS: {
      ...getStandardRouteObject(ROOT, 'bookings'),
      EDIT: undefined,
      VIEW: (id: string) => ({
        PATH_NAME: `${id}`,
        FULL_PATH: `${ROOT}/bookings/${id}`,
      }),
    },

    USERS: {
      PATH_NAME: 'users', // Note: not an actual route
      ADMINS: getStandardRouteObject(`${ROOT}/users`, 'admins', true),
      LAWYERS: getStandardRouteObject(`${ROOT}/users`, 'lawyers', true),
      SALES_AGENTS: getStandardRouteObject(`${ROOT}/users`, 'sales-agents', true),
    },
    EXTERNAL_AGENCIES: {
      PATH_NAME: 'external-agencies',
      SALES_AGENCIES: getStandardRouteObject(`${ROOT}/external-agencies`, 'sales-agencies'),
      LAW_FIRMS: getStandardRouteObject(`${ROOT}/external-agencies`, 'law-firms'),
    },
    REPORTS: {
      PATH_NAME: 'reports',
      OVERVIEW: getViewOnlyRouteObject(`${ROOT}/reports`, 'overview'),
      BOOKINGS: getViewOnlyRouteObject(`${ROOT}/reports`, 'bookings'),
      SUMMARIES: getViewOnlyRouteObject(`${ROOT}/reports`, 'summaries'),
      BUYERS: getViewOnlyRouteObject(`${ROOT}/reports`, 'buyers'),
      SALES_AGENTS: getViewOnlyRouteObject(`${ROOT}/reports`, 'sales-agents'),
    },
  };
};

const urls = {
  ROOT: '/',
  AUTH: authRoutes(),
  PRIVATE: privateRoutes(),
};

export const routeGroups = [`/${urls.PRIVATE.USERS.PATH_NAME}`, `/${urls.PRIVATE.REPORTS.PATH_NAME}`];

export const routeNames: {[x: string]: string} = {
  [`/${urls.PRIVATE.USERS.PATH_NAME}`]: 'Users',
  [`/users/admins`]: 'Admins',
  [`users/sales-agents`]: 'Sales Agents',
  [`users/lawyers`]: 'Lawyers',
  [`/${urls.PRIVATE.PROJECTS.PATH_NAME}`]: 'Projects',
  [`/${urls.PRIVATE.BOOKINGS.PATH_NAME}`]: 'Bookings',
  [`/${urls.PRIVATE.PROJECTS.DISCOUNTS(':id')}`]: 'Discounts',
  [`/${urls.PRIVATE.REPORTS.PATH_NAME}`]: 'Reports',
};

export default urls;
