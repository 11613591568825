import {User} from '@/types/form';

import {ActionTypes} from './actionTypes';

type AppActionSetLoading = {type: typeof ActionTypes.SET_APP_LOADING; payload: {isLoading: boolean}};
type AppActionSetUser = {type: typeof ActionTypes.SET_USER; payload: {user: User}};
type AppActionSetSelectedProjectForReport = {
  type: typeof ActionTypes.SET_CURRENT_PROJECT_FOR_REPORT;
  payload: {id: string; name: string; salesChartId: string};
};
type AppActionClearState = {type: typeof ActionTypes.CLEAR_STATE};

export type AppAction =
  | AppActionSetLoading
  | AppActionSetUser
  | AppActionClearState
  | AppActionSetSelectedProjectForReport;

export const setAppLoading = (isLoading: boolean): AppActionSetLoading => ({
  type: ActionTypes.SET_APP_LOADING,
  payload: {isLoading},
});

export const setUser = (user: User): AppActionSetUser => ({
  type: ActionTypes.SET_USER,
  payload: {user},
});

export const setSelectedProjectForReport = (
  id: string,
  name: string,
  salesChartId: string,
): AppActionSetSelectedProjectForReport => ({
  type: ActionTypes.SET_CURRENT_PROJECT_FOR_REPORT,
  payload: {id, name, salesChartId},
});

export const clearState = (): AppActionClearState => ({
  type: ActionTypes.CLEAR_STATE,
});
